import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectBreakpointSelected,
  breakpointAsync,
  selectVideoInstance,
  selectBreakpointState,
  selectBreakpointName,
  selectBreakpointCorrect,
  setBreakpointName,
  selectBreakpointInstances,
  selectBreakpointDeleteState,
  breakpointDeleteAsync,
  setBreakpointCorrect,
  resetForBreakpoint,
  setBreakpointThink,
  selectBreakpointThink,
  selectBreakpointType,
  setBreakpointType,
  // multi choice
  selectQuestion,
  selectAnswerOne,
  selectAnswerOneCorrect,
  selectAnswerTwo,
  selectAnswerTwoCorrect,
  selectAnswerThree,
  selectAnswerThreeCorrect,
  selectAnswerFour,
  selectAnswerFourCorrect,
  setAnswerOne,
  setAnswerTwo,
  setAnswerThree,
  setAnswerFour,
  setQuestion,
  setAnswerOneCorrect,
  setAnswerTwoCorrect,
  setAnswerThreeCorrect,
  setAnswerFourCorrect,
} from './playbackSlice';

import ReactDOM from 'react-dom';
import TextareaAutosize from 'react-autosize-textarea';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCheck } from '@fortawesome/free-solid-svg-icons';

import './Breakpoint.css';

import formatDuration from 'format-duration';

import { renderBreakpointName } from './userFunction';
import { Button } from '../../ui/Button';

import './Accordion.css';

export function Breakpoint(props) {

  const breakpointSelected = useSelector(selectBreakpointSelected);
  const breakpointState = useSelector(selectBreakpointState);
  const dispatch = useDispatch(); 
  const videoInstance = useSelector(selectVideoInstance);
  const breakpointName = useSelector(selectBreakpointName);
  const breakpointInstances = useSelector(selectBreakpointInstances);
  const breakpointDeleteState = useSelector(selectBreakpointDeleteState);
  const breakpointCorrect = useSelector(selectBreakpointCorrect);
  const breakpointThink = useSelector(selectBreakpointThink);
  const breakpointType = useSelector(selectBreakpointType);

  // multi choice
  const question = useSelector(selectQuestion);
  const answerOne = useSelector(selectAnswerOne);
  const answerOneCorrect = useSelector(selectAnswerOneCorrect);
  const answerTwo = useSelector(selectAnswerTwo);
  const answerTwoCorrect = useSelector(selectAnswerTwoCorrect);
  const answerThree = useSelector(selectAnswerThree);
  const answerThreeCorrect = useSelector(selectAnswerThreeCorrect);
  const answerFour = useSelector(selectAnswerFour);
  const answerFourCorrect = useSelector(selectAnswerFourCorrect);
  //

  const [activeAccordion, setActiveAccordian] = useState('');

  useEffect(()=> {
    // reset responses and form feilds
    dispatch(resetForBreakpoint());
    dispatch(setBreakpointCorrect(breakpointSelected?.correct));
    dispatch(setBreakpointType(breakpointSelected?.type));

    // multi choice
    dispatch(setQuestion(breakpointSelected?.question));
    dispatch(setAnswerOne(breakpointSelected?.answerOne));
    dispatch(setAnswerOneCorrect(breakpointSelected?.answerOneCorrect));
    dispatch(setAnswerTwo(breakpointSelected?.answerTwo));
    dispatch(setAnswerTwoCorrect(breakpointSelected?.answerTwoCorrect));
    dispatch(setAnswerThree(breakpointSelected?.answerThree));
    dispatch(setAnswerThreeCorrect(breakpointSelected?.answerThreeCorrect));
    dispatch(setAnswerFour(breakpointSelected?.answerFour));
    dispatch(setAnswerFourCorrect(breakpointSelected?.answerFourCorrect));
    //

    dispatch(setBreakpointThink(breakpointSelected?.think));
    dispatch(setBreakpointName(breakpointSelected?.name));
  }, [breakpointSelected]);

  const onSubmit = (event) => {
    event.preventDefault(); 
    const breakpoint = {
      id: breakpointSelected.id,
      name: breakpointName,
      myCustom: breakpointInstances,
    };
    return dispatch(breakpointAsync(breakpoint));
  }
  
  const answersOnSubmit = (event) => {
    event.preventDefault(); 
    const breakpoint = {
      id: breakpointSelected.id,
      question,
      answerOne,
      answerOneCorrect,
      answerTwo,
      answerTwoCorrect,
      answerThree,
      answerThreeCorrect,
      answerFour,
      answerFourCorrect,

      myCustom: breakpointInstances,
    };
    dispatch(breakpointAsync(breakpoint));
    return false;
  }
  
  const thinkOnSubmit = (event) => {
    event.preventDefault(); 
    const breakpoint = {
      id: breakpointSelected.id,
      think: breakpointThink,
      myCustom: breakpointInstances,
    };
    return dispatch(breakpointAsync(breakpoint));
  }

  
  const correctOnSubmit = (event) => {
    let type = 'none';
    if(['correct', 'incorrect'].includes(breakpointCorrect )) {
      type = 'correct';
    }
    event.preventDefault(); 
    const breakpoint = {
      id: breakpointSelected.id,
      myCustom: breakpointInstances,
      correct: breakpointCorrect,
      type,
    };
    return dispatch(breakpointAsync(breakpoint));
  } 

  const nameOnChange = (event) => {
    dispatch(setBreakpointName(event.target.value));
  }

  // Multiple Choice
  const questionOnChange = (event) => {
    dispatch(setQuestion(event.target.value));
  }

  const answerOneOnChange = (event) => {
    dispatch(setAnswerOne(event.target.value));
  }
  
  const answerTwoOnChange = (event) => {
    dispatch(setAnswerTwo(event.target.value));
  }
  
  const answerThreeOnChange = (event) => {
    dispatch(setAnswerThree(event.target.value));
  }
  
  const answerFourOnChange = (event) => {
    dispatch(setAnswerFour(event.target.value));
  }

  //
  
  const thinkOnChange = (event) => {
    dispatch(setBreakpointThink(event.target.value));
  }

  const onDeleteSubmit = (event) => {
    const request = {
      id: breakpointSelected.id,
      myCustom: breakpointInstances,
    };

    dispatch(breakpointDeleteAsync(request));
  }

  const renderAccordionContentClass = (item) => {
    const classes = ['accordionContents']
    if(item == activeAccordion) {
      classes.push('accordionOpen')
    } else {
      classes.push('accordionClosed');
    }
    return classes.join(' ');
  }

  const onClickAccordion = (item) => {
    if(item == activeAccordion) {
      setActiveAccordian('');
    } else {
      setActiveAccordian(item);
    }
  }

  const radioOnChange = (event) => {
    dispatch(setBreakpointCorrect(event.target.value));
  }
  
  const typeRadioOnChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setActiveAccordian(value);
    dispatch(setBreakpointType(value));
    const breakpoint = {
      id: breakpointSelected.id,
      video: videoInstance.id,
      type: value,
      myCustom: breakpointInstances,
    };
    return dispatch(breakpointAsync(breakpoint));
  }

  const checked = (local) => {
    if(breakpointCorrect == undefined) {
      return breakpointSelected?.correct == local ? 'checked' : false;
    }
    return breakpointCorrect == local ? 'checked': false;
  }

  const typeChecked = (local) => {
    if(local == breakpointType) {
      return "checked";
    }
    return false;
  }
  
  const onChangeAnswerOneCorrect = (event) => {
    dispatch(setAnswerOneCorrect(true == event.target.checked));
  }

  const onChangeAnswerTwoCorrect = (event) => {
    dispatch(setAnswerTwoCorrect(true == event.target.checked));
  }

  const onChangeAnswerThreeCorrect = (event) => {
    dispatch(setAnswerThreeCorrect(true == event.target.checked));
  }

  const onChangeAnswerFourCorrect = (event) => {
    dispatch(setAnswerFourCorrect(true == event.target.checked));
  }

  return (
    <div className='accordion'>
      <div className='accordionItem'>
        <div className='accordionHeading' onClick={()=> onClickAccordion('details')}>
          <div className='accordionHeadingPadding'>DETAIL</div>
        </div>
        <div className={renderAccordionContentClass('details')}>
          <div className='foregroundSection'>
            <p>Give a name to this breakpoint.</p>
            <div>
              <input type="text" value={breakpointName} onChange={nameOnChange} className='lightInput' name="timeName" placeholder={formatDuration(breakpointSelected.point*1000)} />
              <Button loading={breakpointState} name="SET NAME" onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </div>
      
      <div className='accordionItem'>
        <div className='accordionHeading' onClick={()=> onClickAccordion('none')}>
          <label className='accordionLabel accordionHeadingPadding'>TEXT ONLY <input type="radio" onChange={typeRadioOnChange} name="type" value="none" checked={typeChecked('none')}/></label>
        </div>
        <div className={renderAccordionContentClass('none')}>
          <div className='foregroundSection'>
            <p>Overide the default breakpoint type with custom text. New lines allowed. </p>
            <div>
              <TextareaAutosize rows={3} style={{width: '100%'}} onChange={thinkOnChange} className='lightInput' name="think" value={breakpointThink} />
              <Button loading={breakpointState} name="SET TEXT" onSubmit={thinkOnSubmit} />
            </div>
          </div>
        </div>
      </div>
      
      <div className='accordionItem'>
        <div className='accordionHeading' onClick={()=> onClickAccordion('check')}>
          <label className='accordionLabel accordionHeadingPadding'>COMPREHENSION <input type="radio" onChange={typeRadioOnChange} name="type" value="correct" checked={typeChecked('correct')}/></label>
        </div>
        <div className={renderAccordionContentClass('correct')}>
          <div className='foregroundSection'>
            <p>Check the student's understanding. Give the answer below.</p>
            <div className="radioList">
              <label className='success'>对 <input type="radio" onChange={radioOnChange} checked={checked('correct')} value="correct" name="correct" /></label>
              <label className='caution'>不对 <input type="radio" onChange={radioOnChange} checked={checked('incorrect')} value="incorrect" name="correct" /></label>
              <Button loading={breakpointState} name="SET CHECK" onSubmit={correctOnSubmit} />
            </div>
          </div>
        </div>
      </div>
      
      <div className='accordionItem'>
        <div className='accordionHeading' onClick={()=> onClickAccordion('multi')}>
          <label className='accordionLabel accordionHeadingPadding'>MULTIPLE CHOICE <input type="radio" onChange={typeRadioOnChange} name="type" value="multi" checked={typeChecked('multi')}/></label>
        </div>
        <div className={renderAccordionContentClass('multi')}>
          <div className='foregroundSection'>
            <p>Insert your question here. You can spread out onto 3 lines. </p>
            <TextareaAutosize rows={3} placeholder="Question..." style={{width: '100%'}} onChange={questionOnChange} className='lightInput' name="question" value={question} />
            
            <div className="questionLabel">
              <div className="one">Please fill out at least two answers!</div>
              <div className="two">Correct?</div>
            </div>
            <div>
              <div className='questionCorrect'> 
                <input type="text" value={answerOne} onChange={answerOneOnChange} className='lightInput' name="answerOne" placeholder="Answer #1" />
                <div className='checkboxContainer'>
                  <input type="checkbox" name="answerOneCheck" onChange={onChangeAnswerOneCorrect} checked={answerOneCorrect} />
                </div>
              </div>
              <div className='questionCorrect'>
                <input type="text" value={answerTwo} onChange={answerTwoOnChange} className='lightInput' name="answerTwo" placeholder="Answer #2" />
                <div className='checkboxContainer'>
                  <input type="checkbox" name="answerTwoCheck" onChange={onChangeAnswerTwoCorrect} checked={answerTwoCorrect} />
                </div>
              </div>
              <div className='questionCorrect'>
                <input type="text" value={answerThree} onChange={answerThreeOnChange} className='lightInput' name="answerThree" placeholder="Answer #3" />
                <div className='checkboxContainer'>
                  <input type="checkbox" name="answerThreeCheck" onChange={onChangeAnswerThreeCorrect} checked={answerThreeCorrect} />
                </div>
              </div>
              <div className='questionCorrect'>
                <input type="text" value={answerFour} onChange={answerFourOnChange} className='lightInput' name="answerFour" placeholder="Answer #4" />
                <div className='checkboxContainer'>
                  <input type="checkbox" name="answerFourCheck" onChange={onChangeAnswerFourCorrect} checked={answerFourCorrect} />
                </div>
              </div>
              <Button loading={breakpointState} name="SET QUESTION" onSubmit={answersOnSubmit} />
            </div>
          </div>
        </div>
      </div>

      <div className='accordionItem'>
        <div className='accordionHeading' onClick={()=> onClickAccordion('remove')}>
          <div className='accordionHeadingPadding'>DELETE</div>
        </div>
        <div className={renderAccordionContentClass('remove')}>
          <div className='confirmSection'>
            <p>You can remove this breakpoint. It will not confirm your choice after selecting. You will have to insert a new breakpoint.  </p>
            <div>
            <Button loading={breakpointDeleteState} name="REMOVE" red={true} onSubmit={onDeleteSubmit} />
            </div>
          </div>
        </div>
    </div>
  </div>
  );
}
