import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faRepeat } from '@fortawesome/free-solid-svg-icons';

import './Play.css'

export function Play({onPlay, onRewind, children}) {
    const [actioned, setActioned] = useState();

    const onMyRewind = () => {
        onRewind();
        setActioned(true);
        setTimeout(()=>{
            setActioned(false);
        }, 3000);
    }
    const onMyPlay = () => {
        onPlay();
        setActioned(true);
        setTimeout(()=>{
            setActioned(false);
        }, 3000);
    }
    return (
        <div className="myPlayer">
            {!actioned &&
            <div className="play">
                {onRewind && 
                    <div className="iconButton" onClick={onMyRewind}>
                        <div>
                        <FontAwesomeIcon icon={faRepeat} size="5x" />
                        </div>
                    </div>
                }
                <div className="iconButton" onClick={onMyPlay}>
                    <div>
                    <FontAwesomeIcon icon={faPlay} size="5x" />
                    </div>
                </div>
            </div>
            }
            <div className="description">
                {children}
            </div>
        </div>
    );
}