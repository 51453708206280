import React, { useState } from "react";
import './Multi.css';
import { Play } from "./Play";

export function Multi({ breakpoint, onRewind, onPlay }) {
  const [answerOne, setAnswerOne] = useState();
  const [answerTwo, setAnswerTwo] = useState();
  const [answerThree, setAnswerThree] = useState();
  const [answerFour, setAnswerFour] = useState();

  const [hasAnswered, setHasAnswered] = useState();

  const answerOneOnClick = () => {
    if(breakpoint.answerOneCorrect) {
      setAnswerOne(true);
      setHasAnswered(true);
    } else {
      setAnswerOne(false);
      setHasAnswered(false);
    }
  }
  
  const answerTwoOnClick = () => {
    if(breakpoint.answerTwoCorrect) {
      setAnswerTwo(true);
      setHasAnswered(true);
    } else {
      setAnswerTwo(false);
      setHasAnswered(false);
    }
  }

  const answerThreeOnClick = () => {
    if(breakpoint.answerThreeCorrect) {
      setAnswerThree(true);
      setHasAnswered(true);
    } else {
      setAnswerThree(false);
      setHasAnswered(false);
    }
  }

  const answerFourOnClick = () => {
    if(breakpoint.answerFourCorrect) {
      setAnswerFour(true);
      setHasAnswered(true);
    } else {
      setAnswerFour(false);
      setHasAnswered(false);
    }
  }

  const choose = (
    <div className="interactionContainer">
      {(breakpoint != undefined && breakpoint.question) &&
        <div className="question" style={{whiteSpace: "pre-wrap"}}>{breakpoint.question}</div>
      }
      <div className="answers">
        {(breakpoint != undefined && breakpoint.answerOne) &&
          <div className="answer answerOne">
            <button onClick={answerOneOnClick}>{breakpoint.answerOne}</button>
          </div>
        }
        {(breakpoint != undefined && breakpoint.answerTwo) &&
          <div className="answer answerTwo">
            <button onClick={answerTwoOnClick}>{breakpoint.answerTwo}</button>
          </div>
        }
        {(breakpoint != undefined && breakpoint.answerThree) &&
          <div className="answer answerThree">
            <button onClick={answerThreeOnClick}>{breakpoint.answerThree}</button>
          </div>
        }
        {(breakpoint != undefined && breakpoint.answerFour) &&
          <div className="answer answerFour">
            <button onClick={answerFourOnClick}>{breakpoint.answerFour}</button>
          </div>
        }
      </div>
    </div>
  )

  const getButtonClasses = (whichAnswer, answered) => {
    let classes = ['answer'];
    if(answered) {
      classes.push('correct');
    } else {
      classes.push('incorrect');
    }
    classes.push(whichAnswer);

    return classes.join(' ');
  }

  const result = (
    <Play onRewind={onRewind} onPlay={onPlay}>
      <div className="interactionContainer">
      {(breakpoint != undefined && breakpoint.question) &&
        <div className="question" style={{whiteSpace: "pre-wrap"}}>{breakpoint.question}</div>
      }
      {(answerOne != undefined) &&
        <div className={getButtonClasses('answerOne', answerOne)}><button>{breakpoint.answerOne}</button></div>
      }
      {(answerTwo != undefined) &&
        <div className={getButtonClasses('answerTwo', answerTwo)}><button>{breakpoint.answerTwo}</button></div>
      }
      {(answerThree != undefined) &&
        <div className={getButtonClasses('answerThree', answerThree)}><button>{breakpoint.answerThree}</button></div>
      }
      {(answerFour != undefined) &&
        <div className={getButtonClasses('answerFour', answerFour)}><button>{breakpoint.answerFour}</button></div>
      }
      {(hasAnswered === true) &&
        <span className="display displaySuccess">Correct!</span>
      }
      {(hasAnswered === false) &&
        <span className="display displayFail">Oops. Not correct.</span>
      }
      </div>
    </Play>
  )

  return hasAnswered != undefined ? result : choose;
}