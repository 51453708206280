import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCheck, faProcedures, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';


export function Button(props) {
  let submitButton;
  if(props.loading.status != 'idle') {
    submitButton = <button className="colorButton smallButton" id={props.id} onClick={props.onSubmit}><FontAwesomeIcon icon={faCog} spin /></button>;
  } else {
    switch(props.loading.response.code){
      case 200:
        submitButton = <button className="colorButton smallButton" id={props.id} onClick={props.onSubmit}><FontAwesomeIcon icon={faCheck} /></button>;
      break;
      case 400:
      case 401:
      case 403:
      case 500:
        submitButton = <button className={`colorButton smallButton redButton`} id={props.id} onClick={props.onSubmit}><FontAwesomeIcon icon={faExclamationTriangle} /></button>;
      break;
      default:
        submitButton = <button className={`colorButton smallButton ${(props.red ? 'redButton' : '')}`} id={props.id} onClick={props.onSubmit}>{props.name}</button>;
    }
  }

  return (
    <div>
    {submitButton}
    </div>
  );
}
