import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectMyVideosState,
  selectMyVideos,
  resetMyVideosState,
  myVideosAsync,
  resetVideoGetState,
} from './playbackSlice';

import moment from 'moment';

import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlus, faRepeat } from '@fortawesome/free-solid-svg-icons';

export function My(props) {
  
  useEffect(()=> {
    document.title = "Slow Cool Chinese - Your videos";
  });

  const videosState = useSelector(selectMyVideosState);
  const httpCode = videosState?.response?.code;
  const videos = useSelector(selectMyVideos);
  const navigate = useNavigate();
  //const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("httpCode", httpCode);
    if(httpCode == undefined) {
      dispatch(myVideosAsync({}));
    }
  }, [httpCode]);

  const refreshList = () => {
    dispatch(resetMyVideosState());
  }
  const createVideo = () => {
    dispatch(resetVideoGetState);
    navigate('/create');
  }

  let status;
  if(videosState.status != 'idle') {
      status = <div className='statusIcon'><FontAwesomeIcon icon={faCog} spin size="2x" /></div>
  } else {
    if(httpCode != 200) {
      status = <div className='statusIcon statusError'><button onClick={refreshList}><FontAwesomeIcon icon={faRepeat} /></button></div>
    }
  }
  const plusButton = <span className='statusIcon statusNet'><button onClick={refreshList} title="Refresh list"><FontAwesomeIcon icon={faRepeat} /></button></span>;
  const createButton = <span className='statusIcon statusNet'><button onClick={createVideo} title="Create video"><FontAwesomeIcon icon={faPlus} /></button></span>;
  return (
    <div className="container">
      <div className="foregroundContainer">
        <h1>Your videos {plusButton} {createButton}</h1>
        <p>You'll find the videos you've augmented here. Get started by hitting the plus icon. </p>
        
        <table className='prettyTable'>
          <thead>
            <tr>
              <th className='tcenter largeCol'>Title</th>
              <th className='tright smallCol'>Published</th>
            </tr>
          </thead>
          <tbody className='clickableRows'>
            {videos.map(element => 
              <tr onClick={event => { dispatch(navigate(`/playback/${element.id}`))}}>
                <td className='tleft'><Link to={`/playback/${element.id}`}>{element.title}</Link></td>
                <td className='tright'>{moment(element.createdAt).fromNow()}</td>
              </tr>
            )}
          </tbody>
        </table>
        {status}
      </div>
    </div>
  );
}
