import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  Link, useParams
} from "react-router-dom";

import {
  selectResetState,
  selectResetPassword,
  setResetPassword,
  resetAsync,
} from './features/playback/playbackSlice';

import { renderFlashMessage, renderFlashClassName } from './features/playback/userFunction'


function ResetPassword() {

  useEffect(()=>{
    document.title = "Slow Cool Chinese - Reset your password";
  });

  const dispatch = useDispatch();
  const resetState = useSelector(selectResetState);
  const resetPassword = useSelector(selectResetPassword); 

  const { id, token } = useParams();

  const onChangePassword = (event) => {
    dispatch(setResetPassword(event.target.value));
  }

  return (
    <div className='resetPage container' id='features'>

      <div className='formContainer'>
        <h2>Reset password <span>and level up!</span></h2>
        {!(resetState.status == 'idle' && typeof resetState.response?.code != 'number') &&
        <div className={`flashStatus ${renderFlashClassName(resetState.status, resetState.response)}`}>
              {renderFlashMessage(resetState.status, resetState.response, { success: "Password updated. Now login. 😄"})}
        </div>
        }
        <form>
          <ul>
            <li>
              <label htmlFor="password">New Password</label>
              <input type="password" id='password' onChange={onChangePassword} value={resetPassword} />
            </li>
            <li><button className='loginButton' 
              onClick={(e) => {
                e.preventDefault(); 
                const resetRequest = { 
                  password: resetPassword, 
                  id,
                  token
                };
                console.log("reset", resetRequest);
                return dispatch(resetAsync(resetRequest));
              }}
            >Reset Password</button></li>
          </ul>
        </form>

      </div>
            
    </div>
  );
}

export default ResetPassword;
