import React, { useEffect } from 'react';
import ReactDOM  from 'react-dom';

import {
  BrowserRouter,
  Routes,
  Route,
  useRouteMatch,
  Outlet,
  Link,
  useMatch,
  useResolvedPath,
  LinkProps,
} from "react-router-dom";

import './index.css';
import App from './App';
import Login from './Login';
import Forgotten from './Forgotten';
import ResetPassword from './ResetPassword';
import Signup from './Signup';
import Layout from './Layout';
import { List } from './features/playback/List';
import Terms from './Terms';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import Home from './Home';
import Contact from './Contact';
import { Video } from './features/playback/Video';
import { Playback } from './features/playback/Playback';
import { Watch } from './features/playback/Watch' ;
import { My } from './features/playback/My';

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-230269491-1";
ReactGA.initialize(TRACKING_ID);

// https://v5.reactrouter.com/web/example/url-params

function MyApp() {

  useEffect(() => {
    console.log("tracking id", TRACKING_ID);
    const path = window.location.pathname + window.location.search;
    console.log("path", path);
    ReactGA.pageview(path);
  });

  return (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="login" element={<Login />} />
            <Route path="forgotten" element={<Forgotten />} />
            <Route path="list" element={<List />} />
            <Route path="my" element={<My />} />
            <Route path="create" element={<Video />} />
            <Route path="create/:id" element={<Video />} />
            <Route path="reset/:id/:token" element={<ResetPassword />} />
            <Route path="playback/:id" element={<Playback />} />
            <Route path="playback" element={<Playback />} />
            <Route path="signup" element={<Signup />} />
            <Route path="terms" element={<Terms />} />
          </Route>
          <Route path="chinese/:id" element={<Watch />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
  )};

  ReactDOM.render(<MyApp/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
