import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

import {
  setBreakpointSelected,
  selectVideoGetState,
  selectPublishState,
  selectBreakpointInstances,
  selectVideoInstance,
  videoGetAsync,
  publishAsync,
  selectBreakpointSelected,
  resetBreakpointDeleteState,
  resetBreakpointState,
  resetVideoGetState,
  selectPublishVideo,
  resetVideoState,
  resetVideoInstance,
} from './playbackSlice';

import { Media } from './Media';
import { Breakpoint } from './Breakpoint';

import { renderFlashClassName, renderFlashMessage, renderBreakpointName } from './userFunction';

import { Timer } from './Timer';
import { BreakpointSubmit } from './BreakpointSubmit';

export function Playback(props) {
  const { id } = useParams();
  const videoInstance = useSelector(selectVideoInstance);
  const videoGetState = useSelector(selectVideoGetState);
  const publishState = useSelector(selectPublishState);
  const breakpointSelected = useSelector(selectBreakpointSelected);
  const publishVideo = useSelector(selectPublishVideo);
  const [published, setPublished] = useState(false);

  const publishedHttpCode = publishState?.response?.code;

  useEffect(()=> {
    document.title = "Slow Cool Chinese - Publish Video";
  });

  // reset videoInstance to get fresh on next page
  useEffect(()=>{
    if(videoInstance && published && publishedHttpCode == 200) {
      dispatch(resetVideoInstance({}));
      navigate(`/chinese/${publishVideo.id}`);
    }
  }, [videoInstance, published, publishedHttpCode, publishVideo]);

  useEffect(() => {
    if(videoInstance !== undefined && id === undefined) {
      navigate(`/playback/${videoInstance.id}`);
    }
    if(id !== undefined && videoInstance === undefined){
      dispatch(videoGetAsync({id}));
    }
    if(videoInstance !== undefined && id !== undefined && id != videoInstance.id){
      dispatch(videoGetAsync({id}));
    }
  }, [videoInstance, id]);

  const breakpointInstances = useSelector(selectBreakpointInstances);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectBreakpoint = (element) => {
    console.log("going to select breakpoint");
    dispatch(resetBreakpointDeleteState());
    dispatch(resetBreakpointState());
    dispatch(setBreakpointSelected(element));
  }
  
  const timeTileDom = (item) => {
    let itemClasses = ['myTimeChoice', 'noSelect'];
    if(item.selected) {
      itemClasses.push('selected');
    }
    itemClasses = itemClasses.join(' ');
    return (
      <li onClick={() => selectBreakpoint(item)}
        key={item.id} 
        className={itemClasses}>
          {renderBreakpointName(item, true)}
      </li>
    );
  }

  const onPublish = (event) => {
    event.preventDefault();
    const videoRequest = {
      published: true,
      id: videoInstance.id,
    };
    dispatch(publishAsync(videoRequest))
    setPublished(true);
  }

  return (
    <div className="container">
      <div className="foregroundContainer">
        <h1>Augment a YouTube video</h1>
          <p>
          "ADD BREAKPOINT" will insert a breakpoint at the current position of the video. Afterward you can give it a name and or delete it by selecting a time from the right hand panel. </p>

        <div className='videoForm formContainer'>
          
          {!(videoGetState.status == 'idle' && typeof videoGetState.response?.code != 'number') &&
            <div className={`flashStatus ${renderFlashClassName(videoGetState.status, videoGetState.response)}`}>
                  {renderFlashMessage(videoGetState.status, videoGetState.response, {
                    success: "Fetched video for editing. 😄"
                  })}
            </div>
            }
            
            {!(publishState.status == 'idle' && typeof publishState.response?.code != 'number') &&
            <div className={`flashStatus ${renderFlashClassName(publishState.status, publishState.response)}`}>
                  {renderFlashMessage(publishState.status, publishState.response, {
                    success: "Published video. 😄"
                  })}
            </div>
            }
          
          <div className='saveContainer'>
            <button className='saveButton blackButton' 
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetVideoGetState())
                navigate(`/create/${id}`);
              }}
            >EDIT</button>
          
          <div className='saveContainer'>
            <button className='saveButton noMarginBottom' 
              onClick={onPublish}
            >PUBLISH</button>
          </div>

          </div>
        </div>
      </div>
      <div className="logoContainer">
          { breakpointSelected != null && 
            <Breakpoint />
          }
      </div>
      <Media />
      <div className="scrollContainer">
        <ul className='timesContainer'>
          {breakpointInstances.map((item, index) => timeTileDom(item, index))} 
        </ul>
      </div>
          
        <div className='uiContainer'>
          <BreakpointSubmit />
          <Timer />
        </div>
    </div>
  );
}
