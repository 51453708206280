import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  Link,
} from "react-router-dom";

import {
  forgottenAsync,
  selectForgottenState,
  selectForgottenEmail,
  setForgottenEmail,
} from './features/playback/playbackSlice';

import { renderFlashMessage, renderFlashClassName } from './features/playback/userFunction'


function Password() {

  useEffect(()=>{
    document.title = "Slow Cool Chinese - Forgotten password";
  });

  const dispatch = useDispatch();
  const forgottenState = useSelector(selectForgottenState);
  const forgottenEmail = useSelector(selectForgottenEmail); 

  const onChangeEmail = (event) => {
    dispatch(setForgottenEmail(event.target.value));
  }

  return (
    <div className='forgottenPage container' id='features'>

      <div className='formContainer'>
        <h2>Reset password <span>and level up!</span></h2>
        {!(forgottenState.status == 'idle' && typeof forgottenState.response?.code != 'number') &&
        <div className={`flashStatus ${renderFlashClassName(forgottenState.status, forgottenState.response)}`}>
              {renderFlashMessage(forgottenState.status, forgottenState.response, { success: "Reset successful! Check your email shortly. 😄"})}
        </div>
        }
        <form>
          <ul>
            <li>
              <label htmlFor="email">Email</label>
              <input type="email" id='email' onChange={onChangeEmail} value={forgottenEmail} />
            </li>
            <li><button className='loginButton' 
              onClick={(e) => {
                e.preventDefault(); 
                const forgottenUser = { 
                  email: forgottenEmail, 
                };
                console.log("forgotten user", forgottenUser);
                return dispatch(forgottenAsync(forgottenUser));
              }}
            >Reset Password</button></li>
          </ul>
        </form>

      </div>
            
    </div>
  );
}

export default Password;
