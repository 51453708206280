import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';

import {
  loginAsync,
  selectLoginState,
  selectLoginPassword,
  selectLoginEmail,
  setLoginEmail,
  setLoginPassword,
} from './features/playback/playbackSlice';

import { renderFlashClassName, renderFlashMessage } from './features/playback/userFunction';
  
function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginState = useSelector(selectLoginState);
  const httpCode = loginState?.response?.code;
  const loginPassword = useSelector(selectLoginPassword);
  const loginEmail = useSelector(selectLoginEmail);

  useEffect(()=>{
    document.title = "Slow Cool Chinese - Login";
  });

  useEffect(() => {
    if(httpCode == 200) {
      navigate("/my");
    }
  }, httpCode);

  const onChangePassword = (event) => {
    dispatch(setLoginPassword(event.target.value));
  }

  const onChangeEmail = (event) => {
    dispatch(setLoginEmail(event.target.value));
  }

  const onSubmit = (event) => {
    event.preventDefault(); 
    const loginUser = { 
      email: loginEmail, 
      password: loginPassword, 
    };
    return dispatch(loginAsync(loginUser));
  }

  return (
    <div className='loginPage container' id='features'>

      <div className='formContainer'>
        <h2>Login <span>and live the good life!</span></h2>
        {!(loginState.status == 'idle' && typeof loginState.response?.code != 'number') &&
        <div className={`flashStatus ${renderFlashClassName(loginState.status, loginState.response)}`}>
              {renderFlashMessage(loginState.status, loginState.response, {
                success: "Login successful! Welcome back. 😄"
              })}
        </div>
        }
        <form onSubmit={onSubmit}>
          <ul>
            <li>
              <label htmlFor="email">Email</label>
              <input type="email" id='email' onChange={onChangeEmail} value={loginEmail} />
            </li>
            <li>
              <label htmlFor="password">Password</label>
              <input type="password" id='password' onChange={onChangePassword} value={loginPassword} />
              </li>
            <li><button className='loginButton' 
              onClick={onSubmit}
            >Login</button></li>
            <li><p>I'll admit — <Link to="/forgotten">I forgot my password</Link>.</p></li>
          </ul>
        </form>
      </div>
    </div>
  );
}

export default Login;
