import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectVideosState,
  selectVideos,
  videosAsync,
  resetVideosState
} from './playbackSlice';

import moment from 'moment';

import { renderFlashClassName, renderFlashMessage } from './userFunction';
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faRepeat } from '@fortawesome/free-solid-svg-icons';

export function List(props) {

  useEffect(()=> {
    document.title = "Slow Cool Chinese - Find interactive content";
  });

  const videosState = useSelector(selectVideosState);
  const httpCode = videosState?.response?.code;
  const videos = useSelector(selectVideos);
  const navigate = useNavigate();
  //const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("httpCode", httpCode);
    if(httpCode == undefined) {
      dispatch(videosAsync({}));
    }
  }, [httpCode]);

  const refreshList = () => {
    dispatch(resetVideosState());
  }

  let status;
  if(videosState.status != 'idle') {
      status = <div className='statusIcon'><FontAwesomeIcon icon={faCog} spin size="2x" /></div>
  } else {
    if(httpCode != 200) {
      status = <div className='statusIcon statusError'><button onClick={refreshList}><FontAwesomeIcon icon={faRepeat} /></button></div>
    }
  }

  return (
    <div className="container">
      <div className="foregroundContainer">
        <h1>Find interactive content <span className='statusIcon statusNet'><button onClick={refreshList} title="Refresh list"><FontAwesomeIcon icon={faRepeat} /></button></span></h1>
        <p>The videos below have been carefully augmented by our platform. </p>
        
        <table className='prettyTable'>
          <thead>
            <tr>
              <th className='tcenter largeCol'>Title</th>
              <th className='tright smallCol'>User</th>
              <th className='tright smallCol'>Published</th>
            </tr>
          </thead>
          <tbody className='clickableRows'>
            {videos.map(element => 
              <tr key={element.id} onClick={event => { dispatch(navigate(`/chinese/${element.id}`))}}>
                <td className='tleft'><Link to={`/chinese/${element.id}`}>{element.title}</Link></td>
                <td className='tright'>{element.user.username}</td>
                <td className='tright'>{moment(element.createdAt).fromNow()}</td>
              </tr>
            )}
          </tbody>
        </table>
        {status}
      </div>
    </div>
  );
}
