import formatDuration from 'format-duration';
const truncate = require('truncate');
// A mock function to mimic making an async request for data
const axios = require('axios').default;
const getConfig = () => {
    const config = {
        validateStatus: false,
        withCredentials: true,
    }
    return config;
}
export function videoRequest({ title, youtubeId, thumbnail, url, id, published }) {
  // todo not right
    let ajaxUrl = `${process.env.REACT_APP_SERVER_DOMAIN}api/video`;
    if(id) {
        ajaxUrl = `${process.env.REACT_APP_SERVER_DOMAIN}api/video/${id}`;
    }
    return axios.post(ajaxUrl, {
        title,
        youtubeId,
        thumbnail,
        url,
        published,
    }, getConfig());
}
export function videosRequest() {
    let ajaxUrl = `${process.env.REACT_APP_SERVER_DOMAIN}api/video`;
    return axios.get(ajaxUrl, getConfig());
}
export function isLoggedInRequest() {
    let ajaxUrl = `${process.env.REACT_APP_SERVER_DOMAIN}api/my/logged-in`;
    return axios.get(ajaxUrl, getConfig());
}
export function myVideosRequest() {
    let ajaxUrl = `${process.env.REACT_APP_SERVER_DOMAIN}api/my/video`;
    return axios.get(ajaxUrl, getConfig());
}
export function breakpointRequest({ 
  id, 
  point, 
  name, 
  video, 
  correct, 
  type, 
  think,
  question,
  answerOne,
  answerOneCorrect,
  answerTwo,
  answerTwoCorrect,
  answerThree,
  answerThreeCorrect,
  answerFour,
  answerFourCorrect
}) {
    let ajaxUrl = `${process.env.REACT_APP_SERVER_DOMAIN}api/breakpoint`;
    if(id) {
        ajaxUrl = `${process.env.REACT_APP_SERVER_DOMAIN}api/breakpoint/${id}`;
    }
    return axios.post(ajaxUrl, {
        name,
        point,
        video,
        correct,
        type,
        think,
        question,
        answerOne,
        answerOneCorrect,
        answerTwo,
        answerTwoCorrect,
        answerThree,
        answerThreeCorrect,
        answerFour,
        answerFourCorrect,
    }, getConfig());
}
export function resetRequest({ id, token, password }) {
    return axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}api/auth/reset`, {
        id,
        token,
        password,
    }, getConfig());
}
export function videoGetRequest({id}) {
    return axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}api/video/${id}`, getConfig());
}
export function breakpointDeleteRequest({id}) {
    return axios.delete(`${process.env.REACT_APP_SERVER_DOMAIN}api/breakpoint/${id}`, getConfig());
}
export function signupRequest({username, email, name, password, terms, phone }) {
    return axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}api/user`, {
        username,
        email,
        name,
        password,
        terms,
        phone
    }, getConfig());
}

export function loginRequest({email, password }) {
    return axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}api/auth/login`, {
        email,
        password
    }, getConfig());
}

export function forgottenRequest({email}) {
    return axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}api/auth/forgot`, {
        email,
    }, getConfig());
}

export function contactRequest({firstname, surname, email, comment}) {
    return axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}api/contact`, {
        firstname,
        surname,
        email,
        comment
    }, getConfig());
}

export const renderFlashMessage = (status, response, messages) => {
    if(status != 'idle') {
      return 'loading...';
    }
    switch(response.code) {
      case 200:
        return messages?.success ? messages.success : "Successful!. 😄";
      case 404:
        return messages?.missing ? messages.missing : "Not found. 🧐🔍";
      case 400:
        return "You are missing something. 🤭";
      case 401:
      case 403:
        return messages?.permission ? messages.permission : "You don't have permission for that. 🤔";
      case 500: 
        return "Something went wrong. 🤔";
      default:
        return "Not sure what happened. 😬";
    }
  }
export const renderFlashClassName = (status, response) => {
    if(status != 'idle') {
      return 'loading';
    }
    switch(response.code) {
      case 200:
        return "success";
      case 400:
      case 404:
        return "warning";
      case 401:
      case 403:
      case 500: 
        return "failure";
      default:
        return "unsure";
    }
  }

  export const renderBreakpointName = (breakpoint, toTruncate) => {
    let newString;
    if(breakpoint.name) {
        newString = breakpoint.name;
    } else {
        newString = formatDuration(breakpoint.point*1000);
    }
    return toTruncate ? truncate(newString, 20) : newString;
  }

  export const pauseVideo = (player) => {
    if(player?.pauseVideo){
      try {
        player.pauseVideo();
      } catch(err) {
        console.error("Error pausing video", err);
      }
    } else {
      console.error("player was missing whilst pausing", player);
    }
  }