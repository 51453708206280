import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage, faPlus} from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import './App.css';
import './Home.css';

import PopUp from './features/PopUp';

function Home(props) {
  const [showVideo, setShowVideo] = useState(false);

  useEffect(()=> {
    document.title = "Slow Cool Chinese - Homepage";
  });

  const showVideoOnClick = () => {
    setShowVideo(true);
  }

  const hideVideoOnClick = () => {
    setShowVideo(false);
  }
  
  return (
    <div>
      <div className="container" id="features">
        <h2>Mandarin training!</h2>
        <div>
          <h3 className="top-heading"><span className="faint">language</span> <em><u>acquisition</u></em> <span className="faint">platform</span></h3>
          <div className="three">
            <article className="square observe">
                  <div className="content">
                      <FontAwesomeIcon icon={faLanguage} size="4x" />
                      <h4>Chinese!</h4>
                      <p><em>Will be</em> specific to Mandarin, for now</p>
                  </div>
              </article>
              <article className="square observe">
                  <div className="content">
                      <FontAwesomeIcon icon={faYoutube} size="4x" />
                      <h4>Custom player</h4>
                      <p>Videos that wait for you to speak!</p>
                  </div>
              </article>
              <article className="square observe">
                  <div className="content">
                      <i className="email fas fa-plus"></i>
                      <FontAwesomeIcon icon={faPlus} size="4x" />
                      <h4>DIY videos</h4>
                      <p>Publish your own content</p>
                  </div>
              </article>
          </div>
          <div className="clear"></div>
          <div className="callToAction">
            <p className="tagText">Insert a <b>对不对</b> check mid video.</p>
            <button onClick={showVideoOnClick} className="form-button" id="create-video-button">WATCH DEMO VIDEO</button>
          </div>
        </div>
    </div>
    {(showVideo) && 
      <PopUp onHidePopUp={hideVideoOnClick} videoId="v5MXwq8QhUA" />
    }
  </div>
  );
}

export default Home;
