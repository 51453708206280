import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import formatDuration from 'format-duration';

import { 
  signupRequest, 
  loginRequest, 
  videoRequest, 
  videoGetRequest, 
  forgottenRequest,
  resetRequest,
  contactRequest,
  breakpointRequest,
  videosRequest,
  breakpointDeleteRequest,
  isLoggedInRequest,
  myVideosRequest,
} from './userFunction';

const resetAllOtherResponses = (state, except) => {
  const responseKeys = [
    'loginState', 
    'publishState', 
    'videosState', 
    'myVideosState', 
    'breakpointState',
    'breakpointDeleteState',
    'forgottenState',
    'resetState',
    'signupState',
    'contactState',
    'videoState',
    'videoGetState',
  ];
  responseKeys.forEach( element => {
    if(element != except){
      state[element] = { status: "idle", response: {}};
    }
  });
}

const initialState = {
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,

  // login
  loginState: {
    response: {},
    status: 'idle',
  },
  loginEmail: '',
  loginPassword: '',
  isLoggedIn: false,
  isLoggedInState: {
    response: {},
    status: 'idle', 
  },

  // publish
  publishState: {
    response: {},
    status: 'idle',
  },
  publishVideo: {},
  
  // videos
  videosState: {
    response: {},
    status: 'idle',
  },
  videos: [],
  
  myVideosState: {
    response: {},
    status: 'idle',
  },
  myVideos: [],


  // breakpoint
  breakpointState: {
    response: {},
    status: 'idle',
  },
  breakpointDeleteState: {
    response: {},
    status: 'idle',
  },
  breakpointInstances: [],
  breakpointName: '',
  breakpointCorrect: undefined,
  breakpointThink: '',
  breakpointType: 'none',

  // multiple choice
  question: '',
  answerOne: '',
  answerOneCorrect: false,
  answerTwo: '',
  answerTwoCorrect: false,
  answerThree: '',
  answerThreeCorrect: false,
  answerFour: '',
  answerFourCorrect: false,

  // player
  playerTime: 0,

  // forgotten
  forgottenState: {
    response: {},
    status: 'idle'
  },
  forgottenEmail: '',

  // reset password
  resetPassword: '',
  resetState: {
    response: {},
    status: 'idle',
  },

  // sign up
  signupState: { //
    status: 'idle',
    response: {}
  },
  signupEmail: '',
  signupPhone: '',
  signupPassword: '',
  signupName: '',
  signupUsername: '',
  signupTerms: false,

  // contact
  contactState: { //
    status: 'idle',
    response: {}
  },
  contactFirstname: '',
  contactSurname: '',
  contactEmail: '',
  contactComment: '',

  // video
  videoState: {
    status: 'idle',
    response: {}
  },
  youtubeFormUrl: '',
  videoTitle: '',
  videoInstance: undefined,
  
  videoGetState: { //
    status: 'idle',
    response: {}
  },

};

const myCreateThunk = (name, request) => {
  return createAsyncThunk(
    name,
    async (params) => {
      try {
        const response = await request(params);
        return { code: response.status, data: response.data, myCustom: params.myCustom };
      } catch (err) {
        return { code: 500, data: {}, myCustom: params.myCustom };
      }
    }
  );
}


export const videoAsync = myCreateThunk('playback/videoAsync', videoRequest);
export const publishAsync = myCreateThunk('playback/publishAsync', videoRequest);
export const resetAsync = myCreateThunk('playback/resetAsync', resetRequest);
export const videoGetAsync = myCreateThunk('playback/videoGetAsync', videoGetRequest);
export const myVideosAsync = myCreateThunk('playback/myVideosAsync', myVideosRequest);
export const signupAsync = myCreateThunk('playback/signupAsync', signupRequest);
export const loginAsync = myCreateThunk('playback/loginAsync', loginRequest);
export const forgottenAsync = myCreateThunk('playback/forgottenAsync', forgottenRequest);
export const contactAsync = myCreateThunk('playback/contactAsync', contactRequest);
export const breakpointAsync = myCreateThunk('playback/breakpointAsync', breakpointRequest);
export const breakpointDeleteAsync = myCreateThunk('playback/breakpointDeleteAsync', breakpointDeleteRequest);
export const videosAsync = myCreateThunk('playback/videosAsync', videosRequest);
export const isLoggedInAsync = myCreateThunk('playback/isLoggedInAsync', isLoggedInRequest);

const buildAsyncCases = (builder, myAsync, stateId, successHandler) => {
  builder
      .addCase(myAsync.pending, (state) => {
        state[stateId].status = 'loading';
        state[stateId].response = {};
      })
      .addCase(myAsync.fulfilled, (state, action) => {
        state[stateId].status = 'idle';
        state[stateId].response = action.payload;
        console.log('http response', action.payload);
        if(action.payload && action.payload.code == 200) {
          successHandler(state, action);
        }
      });
}

export const playbackSlice = createSlice({
  name: 'playback',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setWindowHeight: (state, action) => {
      state.windowHeight = action.payload;
    },
    setWindowWidth: (state, action) => {
      state.windowWidth = action.payload;
    },
    // breakpoint instances
    setBreakpointInstances: (state, action) => {
      state.breakpointInstances = action.payload;
    },
    setBreakpointSelected: (state, action) => {
      const selected = action.payload;
      state.breakpointInstances = state.breakpointInstances.map(element => {
        if(selected.id == element.id){
          const mergedElement = { ...element, selected: true };
          console.log("selecting", mergedElement);
          state.breakpointThink = mergedElement.think;
          state.breakpointType = mergedElement.type;
          if(mergedElement.name) {
            state.breakpointName = mergedElement.name;
          } else {
            state.breakpointName = formatDuration(mergedElement.point*1000);
          }
          return mergedElement;
        }
        return { ...element, selected: false };
      });
    },
    setBreakpointUnpaused: (state, action) => {
      const breakpoint = action.payload.breakpoint;
      const breakpoints = action.payload.myCustom;
      state.breakpointInstances = breakpoints.map(element => {
        if(breakpoint.id == element.id) {
          return {...element, paused: false};
        }
        return element;
      });
    },
    setBreakpointPaused: (state, action) => {
      const breakpoint = action.payload.breakpoint;
      const myCustom = action.payload.myCustom;
      console.log("set breakpoint paused", breakpoint);
      if(isNaN(breakpoint?.id)) {
        console.log("paused all", breakpoint);
        state.breakpointInstances = myCustom.map(element => {
          return {...element, paused: false };
        });
        return;
      }
      let paused = false;
      state.breakpointInstances = myCustom.map(element => {
        if(element.id == breakpoint.id) {
          paused = true;
          const newBreakpoint = {...element, paused: true, selected: true};
          console.log("pause in state", newBreakpoint);
          return newBreakpoint;
        } else {
          console.log("un pause");
          return {...element, paused: false, selected: false};
        }
      });
      if(!paused) {
        console.log("missing element");
        const newElement = { ...breakpoint, paused: true, selected: true };
        state.breakpointInstances.push(newElement);
      }
      console.log("finished");
    },
    setBreakpointName: (state, action) => {
      state.breakpointName = action.payload;
    },
    setBreakpointType: (state, action) => {
      state.breakpointType = action.payload;
    },
    setBreakpointThink: (state, action) => {
      state.breakpointThink = action.payload;
    },
    setBreakpointCorrect: (state, action) => {
      state.breakpointCorrect = action.payload;
    },
    resetBreakpointDeleteState: (state) => {
      state.breakpointDeleteState = { status: 'idle', response: {} };
    },
    resetForBreakpoint: (state) => {
      resetAllOtherResponses(state);
    },
    resetBreakpointState: (state) => {
      state.breakpointState = { status: 'idle', response: {} };
    },
    // player
    setPlayerTime: (state, action) => {
      state.playerTime = action.payload;
    },
    // forgotten
    setForgottenEmail: (state, action) => {
      state.forgottenEmail = action.payload;
    },
    // multi choice
    setQuestion: (state, action) => {
      state.question = action.payload;
    },
    setAnswerOne: (state, action) => {
      state.answerOne = action.payload;
    },
    setAnswerOneCorrect: (state, action) => {
      state.answerOneCorrect = action.payload;
    },
    setAnswerTwo: (state, action) => {
      state.answerTwo = action.payload;
    },
    setAnswerTwoCorrect: (state, action) => {
      state.answerTwoCorrect = action.payload;
    },
    setAnswerThree: (state, action) => {
      state.answerThree = action.payload;
    },
    setAnswerThreeCorrect: (state, action) => {
      state.answerThreeCorrect = action.payload;
    },
    setAnswerFour: (state, action) => {
      state.answerFour = action.payload;
    },
    setAnswerFourCorrect: (state, action) => {
      state.answerFourCorrect = action.payload;
    },
    // videos
    resetVideoGetState: (state) => {
      state.videoGetState = { status: 'idle', response: {} };
    },
    resetVideoInstance: (state) => {
      state.videoInstance = undefined;
    },
    resetVideosState: (state) => {
      state.videosState = { status: 'idle', response: {} };
    },
    resetMyVideosState: (state) => {
      state.myVideosState = { status: 'idle', response: {} };
    },
    // reset password
    setResetPassword: (state, action) => {
      state.resetPassword = action.payload;
    },
    // login
    setLoginPassword: (state, action) => {
      state.loginPassword = action.payload;
    },
    setLoginEmail: (state, action) => {
      state.loginEmail = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    //contact
    setContactFirstname: (state, action) => {
      state.contactFirstname = action.payload;
    },
    setContactSurname: (state, action) => {
      state.contactSurname = action.payload;
    },
    setContactEmail: (state, action) => {
      state.contactEmail = action.payload;
    },
    setContactComment: (state, action) => {
      state.contactComment = action.payload;
    },
    // sign up
    setSignupUsername: (state, action) => {
      state.signupUsername = action.payload;
    },
    setSignupPassword: (state, action) => {
      state.signupPassword = action.payload;
    },
    setSignupName: (state, action) => {
      state.signupName = action.payload;
    },
    setSignupEmail: (state, action) => {
      state.signupEmail = action.payload;
    },
    setSignupTerms: (state, action) => {
      state.signupTerms = action.payload;
    },
    setSignupPhone: (state, action) => {
      state.signupPhone = action.payload;
    },
    resetForCreateVideo: (state, action) => {
      state.videoInstance = undefined;
      state.videoTitle = '';
      state.youtubeFormUrl = '';
    },
    resetSignup: (state, action) => {
      state.signupEmail = '';
      state.signupName = '';
      state.signupPassword = '';
      state.signupUsername = '';
      state.signupTerms = false;
    },
    // video
    setYoutubeFormUrl: (state, action) => {
      state.youtubeFormUrl = action.payload;
    },
    setVideoTitle: (state, action) => {
      state.videoTitle = action.payload;
    },
  },
  extraReducers: (builder) => {
    buildAsyncCases(builder, resetAsync, 'resetState', (state) => {
      state.resetPassword = '';
      resetAllOtherResponses(state, 'resetState');
    });

    buildAsyncCases(builder, loginAsync, 'loginState', (state) => {
      state.loginStatus = 'loading';
      state.loginResponse = {};
      state.loginEmail = '';
      state.loginPassword = '';
      state.isLoggedIn = true;
      resetAllOtherResponses(state, 'loginState');
    });
    
    buildAsyncCases(builder, videoAsync, 'videoState', (state, action) => {
      loadVideoIntoState(state, action);
      resetAllOtherResponses(state, 'videoState');
    });

    buildAsyncCases(builder, forgottenAsync, 'forgottenState', (state) => {
      state.forgottenEmail = '';
      resetAllOtherResponses(state, 'forgottenState');
    });
    
    buildAsyncCases(builder, signupAsync, 'signupState', (state) => {
      state.signupEmail = '';
      state.signupName = '';
      state.signupPassword = '';
      state.signupPhone = '';
      state.signupUsername = '';
      state.signupTerms = false;
      state.isLoggedIn = true;
      // action?.payload?.data?.user
      resetAllOtherResponses(state, 'signupState');
    });
    
    buildAsyncCases(builder, contactAsync, 'contactState', (state) => {
      state.contactEmail = '';
      state.signupFirstname = '';
      state.signupSurname = '';
      state.signupComment = '';
      resetAllOtherResponses(state, 'contactState');
    });

    buildAsyncCases(builder, videoGetAsync, 'videoGetState', (state, action) => {
      loadVideoIntoState(state, action);
      resetAllOtherResponses(state, 'videoGetState');
    });

    buildAsyncCases(builder, myVideosAsync, 'myVideosState', (state, action) => {
      if(Array.isArray(action?.payload?.data)){
        state.myVideos = action?.payload?.data;
      }
      resetAllOtherResponses(state, 'myVideosState');
    });
    
    buildAsyncCases(builder, breakpointAsync, 'breakpointState', (state, action) => {
      loadBreakpointIntoState(state, action);
      resetAllOtherResponses(state, 'breakpointState');
    });
    
    buildAsyncCases(builder, breakpointDeleteAsync, 'breakpointDeleteState', (state, action) => {
      const myCustom = action.payload.myCustom;
      const data = action.payload.data;
      state.breakpointInstances = myCustom.filter(element => {
        return element.id != data.id;
      });
      resetAllOtherResponses(state, 'breakpointDeleteState');
    });
    
    buildAsyncCases(builder, videosAsync, 'videosState', (state, action) => {
      loadVideosIntoState(state, action);
      resetAllOtherResponses(state, 'videosState');
    });
    
    buildAsyncCases(builder, isLoggedInAsync, 'isLoggedInState', (state, action) => {
      state.isLoggedIn = true;
    });
    
    buildAsyncCases(builder, publishAsync, 'publishState', (state, action) => {
      state.publishVideo = action.payload.data;
      resetAllOtherResponses(state, 'publishState');
    });
  },
});

const loadVideoIntoState = (state, action) => {
  const video = action?.payload?.data;
  state.videoInstance = video;
  state.videoTitle = video.title;
  if(Array.isArray(video.breakpoints)){
    state.breakpointInstances = video.breakpoints;
  }
  state.youtubeFormUrl = video.url;
}

const loadVideosIntoState = (state, action) => {
  const videos = action?.payload?.data;
  state.videos = videos;
}

const loadBreakpointIntoState = (state, action) => {
  const breakpoint = action?.payload?.data;
  let inserted = false;
  // loop through the current breakpoints
  const newBreakpoints = action.payload.myCustom.map(element => {
    if(element.id === breakpoint.id) {
      inserted = true;
      return {...breakpoint, selected: element.selected };
    }
    return element;
  });
  if(!inserted) {
    newBreakpoints.push(breakpoint);
  }
  state.breakpointInstances = newBreakpoints;
}

export const { 
  setWindowHeight, 
  setWindowWidth,

  // forgotten
  setForgottenEmail,
  setResetPassword,

  // login
  setLoginPassword,
  setLoginEmail,
  setIsLoggedIn,

  // player
  setPlayerTime,

  // breakpoints
  setBreakpointInstances,
  setBreakpointSelected,
  setBreakpointName,
  setBreakpointCorrect,
  resetBreakpointDeleteState,
  resetBreakpointState,
  setBreakpointPaused,
  setBreakpointUnpaused,
  resetForBreakpoint,
  setBreakpointThink,
  setBreakpointType,

  // multi choice
  setQuestion,
  setAnswerOne,
  setAnswerOneCorrect,
  setAnswerTwo,
  setAnswerTwoCorrect,
  setAnswerThree,
  setAnswerThreeCorrect,
  setAnswerFour,
  setAnswerFourCorrect,

  // videos
  resetVideosState,
  resetVideoGetState,
  resetMyVideosState,
  resetVideoInstance,
  resetForCreateVideo,

  // contact 

  setContactComment,
  setContactEmail,
  setContactFirstname,
  setContactSurname,

  // sign up
  setSignupUsername,
  setSignupName,
  setSignupPassword,
  setSignupEmail,
  setSignupPhone,
  setSignupTerms,
  resetSignup,

  // video
  setYoutubeFormUrl, 
  setVideoTitle

} = playbackSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectWindowHeight = (state) => { return state.playback.windowHeight; };
export const selectWindowWidth = (state) => { return state.playback.windowWidth; };
export const selectYoutubeFormUrl = (state) => { return state.playback.youtubeFormUrl; };

// videos data
export const selectVideosState = (state) => { return state.playback.videosState; };
export const selectVideos = (state) => { return state.playback.videos; };
export const selectMyVideosState = (state) => { return state.playback.myVideosState; };
export const selectMyVideos = (state) => { return state.playback.myVideos; };

// player
export const selectPlayerTime = (state) => { return state.playback.playerTime; };

// video data
export const selectVideoState = (state) => { return state.playback.videoState; };
export const selectVideoGetState = (state) => { return state.playback.videoGetState; };
export const selectVideoTitle = (state) => { return state.playback.videoTitle; };
export const selectVideoInstance = (state) => { return state.playback.videoInstance; };

// publish
export const selectPublishVideo = (state) => { return state.playback.publishVideo; };
export const selectPublishState = (state) => { return state.playback.publishState; };

// contact data
export const selectContactFirstname = (state) => { return state.playback.contactFirstname; };
export const selectContactSurname = (state) => { return state.playback.contactSurname; };
export const selectContactEmail = (state) => { return state.playback.contactEmail; };
export const selectContactComment = (state) => { return state.playback.contactComment; };
export const selectContactState = (state) => { return state.playback.contactState; };

// Sign up data
export const selectSignupState = (state) => { return state.playback.signupState; };
export const selectSignupName = (state) => { return state.playback.signupName; };
export const selectSignupPhone = (state) => { return state.playback.signupPhone; };
export const selectSignupUsername = (state) => { return state.playback.signupUsername; };
export const selectSignupEmail = (state) => { return state.playback.signupEmail; };
export const selectSignupPassword = (state) => { return state.playback.signupPassword; };
export const selectSignupTerms = (state) => { return state.playback.signupTerms; };

// Login data
export const selectIsLoggedIn = (state) => { return state.playback.isLoggedIn; };
export const selectIsLoggedInState = (state) => { return state.playback.isLoggedInState; };
export const selectLoginState = (state) => { return state.playback.loginState; };
export const selectLoginEmail = (state) => { return state.playback.loginEmail; };
export const selectLoginPassword = (state) => { return state.playback.loginPassword; };

// Breakpoints
export const selectBreakpointState = (state) => { return state.playback.breakpointState; };
export const selectBreakpointName = (state) => { return state.playback.breakpointName; };
export const selectBreakpointType = (state) => { return state.playback.breakpointType; };
export const selectBreakpointThink = (state) => { return state.playback.breakpointThink; };
export const selectBreakpointCorrect = (state) => { return state.playback.breakpointCorrect; };
export const selectBreakpointDeleteState = (state) => { return state.playback.breakpointDeleteState; };
export const selectBreakpointInstances = (state) => { 
  const beginningArray = [...state.playback.breakpointInstances];
  return beginningArray.sort((a, b) => a.point - b.point);
};
export const selectBreakpointSelected = (state) => {
  const breakpointInstances = state.playback.breakpointInstances;

  if(!breakpointInstances || breakpointInstances.length == 0){
    return undefined;
  }

  return breakpointInstances.find((element) => {
    return element && element.selected == true
  });
}
export const selectBreakpointPaused = (state) => {
  const breakpointInstances = state.playback.breakpointInstances;

  if(!breakpointInstances || breakpointInstances.length == 0){
    return undefined;
  }

  return breakpointInstances.find((element) => {
    return element && element.paused == true
  });
}

// multi choice
export const selectQuestion = (state) => { return state.playback.question; };
export const selectAnswerOne = (state) => { return state.playback.answerOne; };
export const selectAnswerOneCorrect = (state) => { return state.playback.answerOneCorrect; };
export const selectAnswerTwo = (state) => { return state.playback.answerTwo; };
export const selectAnswerTwoCorrect = (state) => { return state.playback.answerTwoCorrect; };
export const selectAnswerThree = (state) => { return state.playback.answerThree; };
export const selectAnswerThreeCorrect = (state) => { return state.playback.answerThreeCorrect; };
export const selectAnswerFour = (state) => { return state.playback.answerFour; };
export const selectAnswerFourCorrect = (state) => { return state.playback.answerFourCorrect; };

// Forgotten
export const selectForgottenState = (state) => { return state.playback.forgottenState; };
export const selectForgottenEmail = (state) => { return state.playback.forgottenEmail; };

// Reset password
export const selectResetState = (state) => { return state.playback.resetState; };
export const selectResetPassword = (state) => { return state.playback.resetPassword; };

export default playbackSlice.reducer;
