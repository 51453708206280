import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectPlayerTime,
} from './playbackSlice';

import formatDuration from 'format-duration';

export function Timer() {
  const playerTime = useSelector(selectPlayerTime);

  return (
    <div className='timer'>
      <h3>{formatDuration(playerTime*1000)}</h3>
    </div> 
  );
}
