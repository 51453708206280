import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { useParams } from 'react-router-dom';

import {
  breakpointAsync,
  selectBreakpointState,
  selectBreakpointInstances,
  selectVideoInstance,
  selectPlayerTime,
} from './playbackSlice';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

export function BreakpointSubmit() {
  const videoInstance = useSelector(selectVideoInstance);
  const playerTime = useSelector(selectPlayerTime);

  const breakpointInstances = useSelector(selectBreakpointInstances);
  const breakpointState = useSelector(selectBreakpointState);

  const dispatch = useDispatch();
    
  //const { id } = useParams();

  const onSubmitBreak = (event) => {
    const breakpointRequest = {
      point: playerTime,
      myCustom: breakpointInstances,
      video: videoInstance.id,
    }
    dispatch(breakpointAsync(breakpointRequest));
  }
  let breakpointButton;
  if(breakpointState.status == 'idle') {
    breakpointButton = <button className='breakButton' onClick={onSubmitBreak}>ADD BREAKPOINT</button>;
  } else {
    breakpointButton = <button className='breakButton' onClick={onSubmitBreak}><FontAwesomeIcon icon={faCog} spin /></button>;
  }

  return (
    <div className='breakpointSubmit'>
      {breakpointButton}
    </div>
  );
}
