import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Link,
  useMatch,
  useResolvedPath,
  LinkProps,
} from "react-router-dom";

import { isLoggedInAsync, selectIsLoggedIn, isLoggedInState, selectIsLoggedInState } from './features/playback/playbackSlice';

function Layout() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isLoggedInState = useSelector(selectIsLoggedInState);
  const httpCode = isLoggedInState?.response?.code;
  const dispatch = useDispatch();

  useEffect(() => {
    if(httpCode == undefined) {
      dispatch(isLoggedInAsync({}));
    }
  }, [httpCode]);

  return (
    <div>
      <nav>
        <div className="boundry">
          <header>
            <div id="logo"><div className="logoInnerContainer"><div><h1><span>SLOW</span></h1></div><span className="tagline">cool chinese</span></div></div>
          </header>
          <video autoPlay preload muted loop playsInline id="nav-video">
            <source src="media/bluepinkswirl.mp4" type="video/mp4" />
          </video>
          <div id="soften-video" />
          <ul>
            <li><CustomLink to="/">Home <span>About the platform</span></CustomLink></li>
            <li><CustomLink to="/list">Learn Mandarin <span>Interactive video!</span></CustomLink></li>
            {isLoggedIn && <li><CustomLink to="/my">Publish <span>Your own content</span></CustomLink></li>}
            {!isLoggedIn && <li><CustomLink to="/login">Login <span>Update your stuff</span></CustomLink></li>}
            {isLoggedIn && <li><a href={`${process.env.REACT_APP_SERVER_DOMAIN}api/auth/logout`}>Logout <span>Be secure</span></a></li>}
            {!isLoggedIn && <li><CustomLink to="/signup">Sign up <span>Make videos</span></CustomLink></li>}
            <li><CustomLink to="/contact">Contact <span>Get in touch</span></CustomLink></li>
          </ul>
          <i id="mic" className="mic fas fa-microphone-alt" />
          <div id="mic-leed"><div id="mic-leed-boundry"><div id="mic-plug" /></div></div>
        </div>
      </nav>
      <main>
          <Outlet />
      </main>
  </div>
  );
}

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div>
      <Link
        className={ match ? "active" : "none" }
        to={to}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
}

export default Layout;
