import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

import {
  selectBreakpointInstances,
  setBreakpointInstances,
  selectPlayerTime,
  setPlayerTime,
  selectBreakpointSelected,
  resetBreakpointDeleteState,
  resetBreakpointState,
  selectVideoInstance,
  setBreakpointPaused,
  selectBreakpointPaused,
  setBreakpointUnpaused
} from './playbackSlice';

import YouTube from 'react-youtube';
import { pauseVideo } from './userFunction';
import { Play } from './Play';
import { Multi } from './Multi';

var getYouTubeID = require('get-youtube-id');

let player;

export function Media(props) {
  //const [playerTime, setPlayertime] = useState(0);
  const playerTime = useSelector(selectPlayerTime);
  const breakpointSelected = useSelector(selectBreakpointSelected);
  const videoInstance = useSelector(selectVideoInstance);
  const breakpointPaused = useSelector(selectBreakpointPaused);
  const breakpointInstances = useSelector(selectBreakpointInstances);
  const [choice, setChoice] = useState(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    setChoice(undefined);
  }, [breakpointPaused]);

  useEffect(() => {

    // do any of the breakpoints

    breakpointInstances.forEach(element => {

      // match a small window time near the current player time

      if((playerTime > element.point) && (playerTime <= (element.point+0.2))) { 
        
        // found a match
        console.log("!!! FOUND A MATCH")
        if(breakpointPaused?.id == element.id || element.paused) {
           console.log("Found a breakpoint that was already paused");
        } else {

          // if it's not paused, then set it to paused and pause it

          console.log("Pausing video", element, "player", playerTime);
          pauseVideo(player)
          dispatch(setBreakpointPaused({ breakpoint: element, myCustom: breakpointInstances }));
        }

        // unpause an alement when it's 1 second past the time
      } else if((playerTime - element.point) > 1 && element.paused) {
        console.log("Un pausing after 1 seconds", element);
        dispatch(setBreakpointUnpaused({breakpoint: element, myCustom: breakpointInstances}));
      } else if((playerTime - element.point) < -1 && element.paused) {
        console.log("Un pausing after 1 seconds", element);
        dispatch(setBreakpointUnpaused({breakpoint: element, myCustom: breakpointInstances}));
      }
    });
  }, [playerTime, breakpointInstances]);

  useEffect(() => {
    const updateTime = () => {
      if(player && player.getCurrentTime) {
        const localPlayerTime = player.getCurrentTime();
        if(playerTime != localPlayerTime){
          dispatch(setPlayerTime(localPlayerTime));
        }
      }
    } 
    let timeUpdater = setInterval(updateTime, 50);

    return () => {
      clearInterval(timeUpdater);
    };
  }, [videoInstance]);

  const saveTarget = (event) => {
      player = event.target; 
  };
  const opts = {
    width: '50%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      //controls: 0,
      modestbranding: 1,
    },
  };
  const getBreakpointDom = (breakpoint) => {
    const videoContainerDom = document.getElementsByClassName('videoContainerContainer')[0];
    // todo: prevent the need to stub this
    let localWidth = 600;
    if(videoContainerDom) {
      localWidth = videoContainerDom.offsetWidth;
    }
    let itemClasses = ['breakpoint'];
    if(breakpoint.point < playerTime) {
      itemClasses.push('past');
    } else {
      itemClasses.push('future');
    }
    if(breakpoint.selected) {
      itemClasses.push("selected");
    }
    // player is undefined sometimes, possibly just when hot reloading the page
    // no player equals black screen otherwise
    // oh then no duration means you can't add breakpoints and no timer is shown
    let videoLength = 0;
    if(player && player.getDuration() > 0) {
      videoLength = player.getDuration();
    }
    let percent;
    if(videoLength == 0 || breakpoint.point == 0) {
      percent = 0;
    } else {
      percent = breakpoint.point / videoLength;
    }

    const localStyles = {
      left: `${(percent*localWidth)-1}px`
    }
    itemClasses = itemClasses.join(' ');
    return (
      <div style={localStyles} key={breakpoint.id} className={itemClasses}></div>
    )
  }

  const pauseAtPoint = (element) => {
    let newPosition = element.point - 4;
    if(newPosition < 0) {
      newPosition = 0;
    }
    return newPosition;
  }
  
  const onLeftClick = (event) => {
    dispatch(resetBreakpointDeleteState());
    dispatch(resetBreakpointState());
    let newBreakpointInstances = breakpointInstances.map((element, index) => {
      if(breakpointInstances[index+1]){
        if(breakpointInstances[index+1].selected) {
          player.seekTo(pauseAtPoint(element));
          player.playVideo();
          return { ...element, selected: true };
        }
      }
      return { ...element, selected: false };
    });
    dispatch(setBreakpointInstances(newBreakpointInstances));
  }
  const onRightClick = (event) => {
    dispatch(resetBreakpointDeleteState());
    dispatch(resetBreakpointState());
    let newBreakpointInstances = breakpointInstances.map((element, index) => {
      if(breakpointInstances[index-1]?.id == breakpointSelected?.id) {
        player.seekTo(pauseAtPoint(element));
        player.playVideo();
        return {...element, selected: true };
      }
      return {...element, selected: false };
    });
    dispatch(setBreakpointInstances(newBreakpointInstances));
  }

  const chooseCorrect = (event) => {
    setChoice(true);
  }
  const chooseIncorret = (event) => {
    setChoice(false);
  }

  let answer;
  if(breakpointPaused) {
    if(breakpointPaused?.correct == "correct"){
      if(choice === true) {
        answer = <p className='displayAnswer'> You were correct!</p>
      } else if (choice === false) {
        answer = <p className='displayAnswer'>Oops! Sorry.</p>
      }
    } else if(breakpointPaused?.correct == "incorrect"){
      if(choice === false) {
        answer = <p className='displayAnswer'>You were correct!</p>
      } else if (choice === true) {
        answer = <p className='displayAnswer'>Oops! Sorry. </p>
      }
    }
  }

  const playOnReset = () => {
    setChoice(undefined);
  }
  
  const onPlay = () => {
    if(player) {
      player.playVideo(); 
    }
  }

  const onRewind = () => {
    player.seekTo(pauseAtPoint(breakpointPaused));
    player.playVideo();
  }

  let defaultText;
  if(breakpointPaused?.think) {
    defaultText = breakpointPaused?.think;
  } else {
    defaultText = "Have a little think or a speak ...";
  }

  return (
    <div className='videoContainerContainer'>
      <div className='customControlsContainer'>
              <div id='customTimelinePreviousBtn' className='timelineButton timelinePrevious noSelect' onClick={onLeftClick}>
                  &lt;
              </div>
              <div className='customTimeline'>
                {breakpointInstances.map((item) => getBreakpointDom(item))} 
              </div>
              <div id='customTimelineNextBtn' className='timelineButton timelineNext noSelect' onClick={onRightClick}>
                &gt;
              </div>
            </div>
      <div className="videoContainer">
        
        <div className='embedContainer'>
          {videoInstance?.url && 
          <YouTube videoId={getYouTubeID(videoInstance.url)}
                onReady={saveTarget} 
                opts={opts}
                />
          }
          {breakpointPaused &&
            <div className='embedUi'>
              {breakpointPaused?.type == "none" &&
                <Play onReset={playOnReset} onRewind={onRewind} onPlay={onPlay} choice={choice} breakpoint={breakpointPaused}>
                  <p style={{whiteSpace: "pre-wrap"}}>{defaultText}</p>
                  </Play>
              }
              {(choice != undefined && breakpointPaused?.type == "correct") &&
                <Play onReset={playOnReset} onRewind={onRewind} onPlay={onPlay} choice={choice} breakpoint={breakpointPaused}>
                  {answer}
                  </Play>
              }
              {(choice == undefined && breakpointPaused?.type == "correct")  &&
                <div className='interactionContainer'>
                  <div className='correctness'>
                    <button className='button-correct' onClick={chooseCorrect}>对</button>
                    <button className='button-incorrect' onClick={chooseIncorret}>不对</button>
                  </div>
                </div>
              }
              {(breakpointPaused?.type == "multi") &&
                <Multi breakpoint={breakpointPaused} onRewind={onRewind} onPlay={onPlay} />
              }
            </div>
          }
          </div>
      </div>
    </div>
  )
}
