import React from "react";
import "./PopUp.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import YouTube from 'react-youtube';

function PopUp(props) {

  const hideVideoOnClick = () => {
    props.onHidePopUp();
  }
  
  const opts = {
    width: '50%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      //controls: 0,
      modestbranding: 1,
    },
  };

  return (
    <div className='popUpContainer' onClick={hideVideoOnClick}>
      <div className='popUpForegroundContainer'>
        <div className='popUpForeground'> 
          <div className='popUpGrid'>
            <div className='popUpTop'>
              <div className='popupIcon'>
                <FontAwesomeIcon icon={faXmark} size="3x" style={{
                  color: 'white',
                  }} />
                </div>
            </div>
            <div className='popUpBottom'>
              <div className='popUpBottomVideoPosition'>
              <div className="embedContainer">
              <YouTube videoId={props.videoId}
                  opts={opts}
                  />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopUp;