import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import {
  selectVideoGetState,
  selectVideoInstance,
  videoGetAsync,
} from './playbackSlice';

import { Media } from './Media';

import { renderFlashClassName, renderFlashMessage, renderBreakpointName } from './userFunction';

export function Watch(props) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(()=> {
    document.title = "Slow Cool Chinese - Watch a video -";
  });

  const { id } = useParams();
  const videoInstance = useSelector(selectVideoInstance);
  const videoGetState = useSelector(selectVideoGetState);

  useEffect(() => {
    if(id !== undefined && videoInstance === undefined){
      dispatch(videoGetAsync({id}));
    }
    if(videoInstance !== undefined && id !== undefined && id != videoInstance.id){
      dispatch(videoGetAsync({id}));
    }
  }, [videoInstance, id]);
  
  useEffect(()=> {
    if(videoInstance){
      document.title = `Slow Cool Chinese - Watch a video - ${videoInstance.title}`;
    }
  }, [videoInstance]);

  return (
    <div className="cleanContainer">
      {videoInstance && 
        <div className='watchDescription'>
          <h1>{videoInstance?.title}</h1>
          <p>{videoInstance?.user?.username} has prepared this video for you and it's powered by <Link to='/'>slow cool chinese</Link>! </p>

          <p>Please don't run the video in full screen mode. It prevents our ability to add in our own interactive elements. </p>
        </div>
      }
      {videoGetState?.response?.code == 404 &&
        <div className='watchDescription'>
        <h1>Chinese Videos</h1>
        <p>Normally this is where you'd watch an awesome video to learn Chinese. 
          We can't seem to find this one though — the link is broken somehow. 
        </p>

        <p><Link to="/list">Find it in this list</Link>. </p>
        </div>
      }
      {!(videoGetState.status == 'idle' && typeof videoGetState.response?.code != 'number') &&
      <div className={`flashStatus ${renderFlashClassName(videoGetState.status, videoGetState.response)}`}>
            {renderFlashMessage(videoGetState.status, videoGetState.response, {
              success: "Fetched video. Grab the popcorn. 😄"
            })}
      </div>
      }
      {videoInstance?.published &&
        <Media />
      }
      {!videoInstance?.published &&
      <p>This video is not published yet. </p>
      }
      <p className='paddingBottom'>Check out the parent website: <Link to='/'>slow cool chinese</Link>.</p>
    </div>
  );
}
