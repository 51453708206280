import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCheck } from '@fortawesome/free-solid-svg-icons';

import {
  contactAsync,
  selectContactFirstname,
  selectContactSurname,
  selectContactComment,
  selectContactEmail,
  selectContactState,
  setContactEmail,
  setContactFirstname,
  setContactSurname,
  setContactComment,
} from './features/playback/playbackSlice';

import { renderFlashClassName, renderFlashMessage } from './features/playback/userFunction';

function Contact() {
  
  useEffect(()=> {
    document.title = "Slow Cool Chinese - Contact";
  });

  const dispatch = useDispatch();
  const contactFirstname = useSelector(selectContactFirstname);
  const contactSurname = useSelector(selectContactSurname);
  const contactComment = useSelector(selectContactComment);
  const contactEmail = useSelector(selectContactEmail);
  const contactState = useSelector(selectContactState);

  const [activeFirstname, setActiveFirstname] = useState(false);
  const [activeSurname, setActiveSurname] = useState(false);
  const [activeEmail, setActiveEmail] = useState(false);
  const [activeComment, setActiveComment] = useState(false);

  const onChangeFirstname = (event) => {
    const value = event.target.value;
    setActiveFirstname(value.length > 0 ? true : false);
    dispatch(setContactFirstname(value));
  }

  const onChangeSurname = (event) => {
    const value = event.target.value;
    setActiveSurname(value.length > 0 ? true : false);
    dispatch(setContactSurname(value));
  }

  const onChangeEmail = (event) => {
    const value = event.target.value;
    setActiveEmail(value.length > 0 ? true : false);
    dispatch(setContactEmail(event.target.value));
  }

  const onChangeComment = (event) => {
    const value = event.target.value;
    setActiveComment(value.length > 0 ? true : false);
    dispatch(setContactComment(value));
  }
  const onFocus = (event) => {
    switch(event.target.name) {
      case "firstname":
        setActiveFirstname(true);
      break;
      case "surname":
        setActiveSurname(true);
      break;
      case "comment":
        setActiveComment(true);
      break;
      case "email":
        setActiveEmail(true);
      break;
    }
  }

  const onBlur = () => {
    setActiveFirstname(contactFirstname.length > 0 ? true : false);
    setActiveSurname(contactSurname.length > 0 ? true : false);
    setActiveComment(contactComment.length > 0 ? true : false);
    setActiveEmail(contactEmail.length > 0 ? true : false);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const contactRequest = {
      firstname: contactFirstname,
      surname: contactSurname,
      email: contactEmail,
      comment: contactComment
    };
    console.log("contact submit", contactRequest);
    dispatch(contactAsync(contactRequest));
  }
  let submitButton;
  if(contactState.status != 'idle') {
    submitButton = <button className="form-button" id="contactButton" onClick={onSubmit}><FontAwesomeIcon icon={faCog} spin /></button>;
  } else {
    switch(contactState.response.code){
      case 200:
        submitButton = <button className="form-button success" id="contactButton" onClick={onSubmit}><FontAwesomeIcon icon={faCheck} /></button>;
      break;
      case 400:
        submitButton = <button className="form-button error" id="contactButton" onClick={onSubmit}><span id="contactButtonText">SEND</span></button>;
      break;
      default:
        submitButton = <button className="form-button" id="contactButton" onClick={onSubmit}><span id="contactButtonText">SEND</span></button>;
    }
  }

  return (
    <div id="contact" className="observe">
      <div className="opaqueBorder"></div>
      <div className="boundry">
      <div className="form contactForm">
        {!(contactState.status == 'idle' && typeof contactState.response?.code != 'number') &&
        <div className={`flashStatus ${renderFlashClassName(contactState.status, contactState.response)}`}>
              {renderFlashMessage(contactState.status, contactState.response, {
                success: "Message sent! Will reply soon. "
              })}
        </div>
        }
        <form onSubmit={onSubmit}>
          <div>
            <div className={"inputWrapper twoCol " + (activeFirstname ? 'hasFocus' : '')}>
              <input type="text" className="listenInput" onBlur={onBlur} onChange={onChangeFirstname} onFocus={onFocus} value={contactFirstname}  name="firstname" id="firstname" />
              <label for="firstname">Firstname</label>
            </div>
            <div className={"inputWrapper twoCol " + (activeSurname ? 'hasFocus' : '')}>
              <input type="text" className="listenInput" onBlur={onBlur} onChange={onChangeSurname} onFocus={onFocus} value={contactSurname} name="surname" id="surname" />
              <label for="surname">Surname</label>
            </div>
          </div>
          <div className={"inputWrapper " + (activeEmail ? 'hasFocus' : '')}>
            <input type="email" className="listenInput" onBlur={onBlur} onChange={onChangeEmail} onFocus={onFocus} value={contactEmail}  name="email" id="email" />
            <label for="contact-email">Email</label>
          </div>
          <div className={"textareaWrapper " + (activeComment ? 'hasFocus' : '')}>
            <textarea name="comment" className="listenInput" onBlur={onBlur} onChange={onChangeComment} onFocus={onFocus} id="comment" rows="5" wrap>{contactComment}</textarea>
            <label for="comment">Comment</label>
          </div>
          <div className="inputWrapper submitWrapper">
            {submitButton}
          </div>
        </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
