import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setYoutubeFormUrl,
  selectYoutubeFormUrl,
  selectVideoTitle,
  setVideoTitle,
  videoAsync,
  selectVideoState,
  selectVideoGetState,
  selectVideoInstance,
  videoGetAsync,
  resetVideoGetState,
  resetForCreateVideo,
} from './playbackSlice';

import { renderFlashClassName, renderFlashMessage } from './userFunction';
import { useNavigate, useParams } from "react-router-dom";

export function Video(props) {
  const youtubeFormUrl = useSelector(selectYoutubeFormUrl);
  const videoState = useSelector(selectVideoState);
  const videoGetState = useSelector(selectVideoGetState);
  const videoTitle = useSelector(selectVideoTitle);
  const videoInstance = useSelector(selectVideoInstance);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(()=>{
    document.title = "Slow Cool Chinese - Create a new video";
  });

  useEffect(() => {
    if(id !== undefined && videoInstance === undefined){
      dispatch(videoGetAsync({id}));
    }
    if(id == undefined && videoInstance) {
      dispatch(resetForCreateVideo({}));
    }
  }, [videoInstance, id]);

  const onChangeUrlField = (event) => {
    dispatch(setYoutubeFormUrl(event.target.value));
  }

  const onChangeVideoTitle = (event) => {
    dispatch(setVideoTitle(event.target.value));
  }

  const onSubmit = (event) => {
    event.preventDefault(); 
    const video = { 
      title: videoTitle,
      url: youtubeFormUrl,
    };
    if(id){
      video.id = id;
    }
    console.log("video", video);
    dispatch(resetVideoGetState());
    navigate(`/playback`);
    return dispatch(videoAsync(video));
  }

  let saveButtonText = id != undefined ? 'NEXT' : 'NEXT';

  return (
    <div className="container">
      <div className="foregroundContainer">
        <h1>Augment a YouTube video</h1>
        <p>You will receive a public URL with the original YouTube video embedded, but 
          controlled by our webpage. You will need to add your own pause points — aka a breakpoint. 
          At every position you want a video to pause, insert a breakpoint. </p>
        <div className='videoForm formContainer'>
          <div>
            {!(videoState.status == 'idle' && typeof videoState.response?.code != 'number') &&
            <div className={`flashStatus ${renderFlashClassName(videoState.status, videoState.response)}`}>
                  {renderFlashMessage(videoState.status, videoState.response, {
                    success: "One video coming up. 😄"
                  })}
            </div>
            }
            {!(videoGetState.status == 'idle' && typeof videoGetState.response?.code != 'number') &&
            <div className={`flashStatus ${renderFlashClassName(videoGetState.status, videoGetState.response)}`}>
                  {renderFlashMessage(videoGetState.status, videoGetState.response, {
                    success: "Fetched video for editing. 😄"
                  })}
            </div>
            }
          </div>
          <div className='titleContainer'>
            <input type='text' placeholder='Enter title' onChange={onChangeVideoTitle} value={videoTitle} />
          </div>
          <div className='youtubeUrl'>
            <input type='text' placeholder='Enter 📽️ YouTube URL' id='updateUrl' name="updateUrl" value={youtubeFormUrl} onChange={onChangeUrlField} />
          </div>
          <div className='saveContainer'>
            <button className='saveButton blackButton' 
              onClick={onSubmit}>{saveButtonText}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
