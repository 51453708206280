import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  signupAsync,
  selectSignupState,
  selectSignupName,
  selectSignupPassword,
  selectSignupUsername,
  selectSignupEmail,
  setSignupEmail,
  setSignupName,
  setSignupPassword,
  setSignupUsername,
  setSignupPhone,
  selectSignupPhone,
  setSignupTerms,
  selectSignupTerms
} from './features/playback/playbackSlice';

import { Link, useNavigate } from 'react-router-dom';
import { renderFlashClassName, renderFlashMessage, signupRequest } from './features/playback/userFunction';

import './Form.css';

//const validator = require("email-validator");
import validator from 'email-validator';

function Signup() {

  useEffect(()=>{
    document.title = "Slow Cool Chinese - Sign up";
  });

  const dispatch = useDispatch();
  const signupState = useSelector(selectSignupState);
  const signupName = useSelector(selectSignupName);
  const signupUsername = useSelector(selectSignupUsername);
  const signupPassword = useSelector(selectSignupPassword);
  const signupEmail = useSelector(selectSignupEmail);
  const signupPhone = useSelector(selectSignupPhone);
  const signupTerms = useSelector(selectSignupTerms);
  const navigate = useNavigate();

  const httpCode = signupState.response?.code;
  
  useEffect(() => {
    if(httpCode == 200) {
      navigate("/my");
    }
  }, httpCode);

  const onChangeName = (event) => {
    dispatch(setSignupName(event.target.value));
  }
  
  const onChangeUsername = (event) => {
    dispatch(setSignupUsername(event.target.value));
  }
  
  const onChangePassword = (event) => {
    dispatch(setSignupPassword(event.target.value));
  }

  const onChangeEmail = (event) => {
    dispatch(setSignupEmail(event.target.value));
  }
  
  const onChangePhone = (event) => {
    dispatch(setSignupPhone(event.target.value));
  }

  const onChangeTerms = (event) => {
    dispatch(setSignupTerms(true == event.target.checked));
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const signupUser = { 
      username: signupUsername, 
      email: signupEmail,
      phone: signupPhone,
      password: signupPassword, 
      name: signupName,
      terms: signupTerms,
    };
    return dispatch(signupAsync(signupUser));
  }

  const showValidationMessages = signupState?.response?.code == 400;
  let usernameIsValid = signupUsername.length >= 6;
  const usernameIsFound = !!signupState?.response?.data?.usernameFound;
  if(usernameIsFound) {
    usernameIsValid = false;
  }
  //const usernameIsValid = signupState?.response?.data?.usernameLength ? false : true;
  let emailIsValid = validator.validate(signupEmail);
  const emailIsFound = !!signupState?.response?.data?.emailFound;
  if(emailIsFound) {
    emailIsValid = false;
  }
  const passwordIsValid = signupPassword.length >= 8;
  const termsIsValid = signupTerms == true;
  const phoneIsValid = signupPhone.length >= 8;

  return (
    <div className='signupPage container' id='features'>

    <div className='formContainer'>
      <h2>Sign up <span>and live the good life!</span></h2>
      {!(signupState.status == 'idle' && typeof signupState.response?.code != 'number') &&
      <div className={`flashStatus ${renderFlashClassName(signupState.status, signupState.response)}`}>
            {renderFlashMessage(signupState.status, signupState.response, {
              success: "You are in the club now! Signup successful. 😄"
            })}
      </div>
      }
      <form onSubmit={onSubmit}>
        <ul>
          <li>
            <label htmlFor="name">Name</label>
            <input type="text" id='name' onChange={onChangeName} value={signupName} />
          </li>
          <li className={showValidationMessages && !usernameIsValid ? 'formRow validationError' : 'formRow'}>
            <label htmlFor="username">Username {showValidationMessages && !usernameIsValid && <span className='asterisk'>*</span>}</label>
            <input type="text" id='username' onChange={onChangeUsername} value={signupUsername} />
            {(showValidationMessages && (!usernameIsValid && !usernameIsFound)) &&
              <p className='validationMessage'>Username needs to be at least 6 characters long</p>
            }
            {(showValidationMessages && usernameIsFound) &&
              <p className='validationMessage'>Username is already in use</p>
            }
          </li>
          <li className={showValidationMessages && !emailIsValid ? 'formRow validationError' : 'formRow'}>
            <label htmlFor="email">Email {showValidationMessages && !emailIsValid && <span className='asterisk'>*</span>}</label>
            <input type="email" id='email' onChange={onChangeEmail} value={signupEmail} />
            {(showValidationMessages && (!emailIsValid && !emailIsFound)) &&
              <p className='validationMessage'>Email needs to be a valid email address</p>
            }
            {(showValidationMessages && emailIsFound) &&
              <p className='validationMessage'>This email address is already in use</p>
            }
          </li>
          <li className={showValidationMessages && !phoneIsValid ? 'formRow validationError' : 'formRow'}>
            <label htmlFor="phone">(International) Mobile {showValidationMessages && !phoneIsValid && <span className='asterisk'>*</span>}</label>
            <input type="text" id='phone' onChange={onChangePhone} value={signupPhone} />
            {(showValidationMessages && !phoneIsValid) &&
              <p className='validationMessage'>Mobile must be at least 8 numbers long</p>
            }
          </li>
          <li className={showValidationMessages && !passwordIsValid ? 'formRow validationError' : 'formRow'}>
            <label htmlFor="password">Password {showValidationMessages && !passwordIsValid && <span className='asterisk'>*</span>}</label>
            <input type="password" id='password' onChange={onChangePassword} value={signupPassword} />
            {(showValidationMessages && !passwordIsValid) &&
              <p className='validationMessage'>Password must be at least 8 characters long</p>
            }
            </li>
            <li className={showValidationMessages && !termsIsValid ? 'formRow validationError' : 'formRow'}>
              <label htmlFor='terms' className='termsInput'>
                You agree to the <Link to='/terms' target="_blank">terms and conditions</Link> {showValidationMessages && !termsIsValid && <span className='asterisk'>*</span>}
                <input type="checkbox" id="terms" onChange={onChangeTerms} checked={signupTerms} />
              </label>
              
            {(showValidationMessages && !termsIsValid) &&
              <p className='validationMessage'>You must agree to the terms</p>
            }
            </li>
          <li><button className='signupButton' 
            onClick={onSubmit}
          >Signup</button></li>
        </ul>
      </form>

    </div>
  </div>
  );
}

export default Signup;
