import React, { useEffect } from 'react';

function Terms() {

  useEffect(()=> {
    document.title = "Slow Cool Chinese - Terms and Conditions";
  });

  return (
      <div className='formContainer textPage'>
        <h1>Terms and Conditions</h1>
        <p>Slow Cool Chinese is a project by an experienced web developer
          (Ashley Simons) from South Australia, Adelaide. It is not at present
          owned by a legal business entity. Please carefully read the following
          terms and conditions for using the website/product. Later the code, data
          and licences may be transferred to a legal business entity, and you agree
          to your data and content being transferred in this way. </p>

        <p>Using this website/service/product means that you agree to and
          note the following conditions and statements, acknowledging how 
          this project operates. </p>
          <ol>
<li>Project owner will use cookies to track some internal functions of the
  software. Eg: to identify you as a user for security reasons. </li>
<li>Project owner will use cookies/javascript from third party services.
  Eg: to track user behaviour or integrate with a third party payment provider.</li>
<li>A basic level of conscientiousness is practised to ensure the security and
  privacy of the website and its data, but relies on the work of third parties
  to maintain the software that the website is dependent on, and as such
  Ashley Simons does not offer the service with any warranty. </li>
<li>You agree not to hold the owner of this website responsible for
  loss of business or damages or liability. </li>
<li>This product/website is at a new and experimental stage of development
  and Ashley does not offer a guarantee as to the reliability of its functioning.</li>
<li>By using this website you licence me to use the content that you
  contribute indefinitely. Hopefully the site will offer ways to
  delete your content and you may attempt making a special request to
  remove the content. However, there will be backups of that data that
  the website owner will not especially delete, but may eventually
  automatically be archived or removed as backups are retotated. There may
  be technologies used that do a poor job of completely removing private
  data after being deleted. </li>
  <li>You will periodically come back and read this page for changes. 
    Ashley may attempt to notify users when there are important updates.  </li>
    <li>You agree to receive system, security and marketing emails related to this website. 

      Slow Cool Chinese will provide the means to opt out of marketing emails. It may be necessary to send
      you system emails, such as to fulfill your request to reset your password. In the event new system
      emails are created, the site will let you modify your email preferences in an account area.  
    </li>
 
          </ol>
      </div>
  );
}

export default Terms;
